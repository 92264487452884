<template>
  <div class="flexcolumnstart clinical-projects">
    <img src="@/images/clinical/clinical-post.png" class="post" />
    <div class="list">
      <!-- 列表 -->
      <div v-for="item in list" :key="item.id">
        <!-- cell -->
        <card style="padding:0;">
          <div class="cell-content">
            <div class="title">
              {{ item.title }}
            </div>
            <span
              :class="[
                item.status != 'RUNNING' ? 'status status-disable' : 'status',
              ]"
            >
              {{ statusText(item.status) }}
            </span>
            <div class="row">
              <span class="row-title">药物</span>
              <span>{{ item.drug }}</span>
            </div>
            <div style="height:10px"></div>
            <div class="row">
              <span class="row-title">适应证</span>
              <span>{{ item.indication }}</span>
            </div>
          </div>
          <div
            v-if="item.status == 'RUNNING'"
            class="flexrowcenter btn"
            @click="toDetail(item)"
          >
            申请
          </div>
        </card>
        <!-- cell -->
      </div>
      <!-- 列表 -->
    </div>

    <div class="flexrowcenter qa">
      <a href="/#/clinicalVolunteer/qa"> 什么是临床试验？</a>
    </div>
    <div class="g-safe-area-bottom"></div>
  </div>
</template>

<script>
import Card from "@/components/card.vue";
import { List } from "vant";
export default {
  components: { Card, List },
  data() {
    return {
      list: [],
    };
  },
  mounted() {
    if (!this.$route.query.token) {
       this.$dialog.alert({
                confirmButtonColor: "#12BEAE",
                title: "提醒",
                message: '请先登录树兰医院小程序',
              }).then(() => {
                 wx.miniProgram.reLaunch({
              url: `/pages/common-package/login/login`,
            });
              });
    }else{
    this.getListRequest();
    this.scrollToTop();
    }
  },
  computed: {},
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    toDetail(item) {
      if (item.status != "RUNNING") {
        return;
      }
      this.$router.push({
        path: "/clinicalVolunteer/detail",
        query: { id: item.id },
      });
    },
    statusText: function(status) {
      return status == "RUNNING" ? "招募中" : "已结束";
    },
    getListRequest(page = 1, size = 100) {
      this.$axios
        .get(`/clinical/recruitment/list/online?size=${size}&page=${page}`, {})
        .then((res) => {
          let list = res.data.list;
          this.list = list;
        });
    },
  },
};
</script>

<style lang="scss">
.clinical-projects {
  background: #fafafc;
  .post {
    width: 100%;
    height: auto;
  }
  .list {
    margin-top: -20px;
    .cell-content {
      padding: 28px;
      .title {
        font-size: 32px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #040814;
        line-height: 45px;
      }
      .status {
        font-size: 32px;
        font-weight: 500;
        color: #f87c2e;
        padding: 12px 24px;
        background: #fff4ed;
        border-radius: 14px;
        align-self: flex-start;
        flex: 0 0 auto;
        width: auto;
        margin: 20px 0;
      }
      .status-disable {
        color: #c4c6d0;
        background: #eef1f5;
      }
      .row {
        font-size: 28px;
        font-weight: 400;
        color: #040814;

        .row-title {
          color: #4f5265;
          width: 162px;
        }
      }
    }
    .btn {
      height: 94px;
      background: #effaf8;
      border-radius: 0px 0px 14px 14px;
      align-items: center;
      font-size: 36px;
      font-weight: 500;
      color: #12beae;
      line-height: 40px;
    }
  }
  .qa {
    height: 40px;
    font-size: 28px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    line-height: 40px;
    padding: 68px 0;
    a {
      color: #485294;
      border-bottom: 2px solid #485294;
    }
  }
}
</style>
