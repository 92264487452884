<template>
  <div class="card"><slot></slot></div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.card {
  background: #ffffff;
  box-shadow: 0px 2px 10px 0px #eaeef2;
  border-radius: 24px;
  margin: 0 24px 28px;
  padding: 28px;
  box-sizing: border-box;
}
</style>
